import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers at Crawley College</title>
        <meta
          name="description"
          content="Search the latest roles at Crawley College"
        />
      </Helmet>

      <div className="home vacancies-home vacancies-home-crawley">
        <section className="app-content">
          <h1>Careers at Crawley College</h1>
          <div className="content-container">
            <SmartFeedFiltersComponent
              vacancyLoading={vacancyLoading}
              setVacancyLoading={setVacancyLoading}
              apiKey={"3Wh-wj_AosfQ4Ku6eyWeu0nsoFzIGqGMNXZnYpHaSug"}
              group={true}
              regionPreFilter={"Crawley College"}
              gdprKey="qDLqYFLXGvdpPKRe1ghrYvyyPo7rfAJ0PzfmYP5BCoQ"
              CompanyGroupName="Chichester College Group"
              VacancyPageBaseURL="https://chichestercollegegroupcareers.co.uk/vacancies/vacancy/"
              ReturnToSearchURL="https://chichestercollegegroupcareers.co.uk/vacancies/"
            />
          </div>
        </section>
      </div>
    </Layout>
  )
}
